<template>
  <el-dialog
    :append-to-body="true"
    :visible.sync="dialog"
    :title="isAdd ? '新增' : '编辑'"
    width="500px"
  >
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="150px">
      <el-form-item label="标签名称" prop="typeName">
        <el-input v-model="form.typeName" style="width: 300px;"/>
      </el-form-item>
      <el-form-item label="标签英文名称" prop="typeNameEn">
        <el-input v-model="form.typeNameEn" style="width: 300px;"/>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-radio v-model="form.status" label="1">正常</el-radio>
        <el-radio v-model="form.status" label="0">锁定</el-radio>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit('form')">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { add, edit } from "@/api/cms/srCmsTagType";
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      dialog: false,
      form: {
        id: "",
        typeName: "",
        typeNameEn: "",
        status: "1"
      },
      rules: {
        status: [{ required: true, message: "请选择状态类型", trigger: "blur" }]
      }
    };
  },
  methods: {
    cancel() {
      this.resetForm();
    },
    doSubmit(form) {
      this.loading = true;
      if (this.isAdd) {
        this.doAdd(form);
      } else this.doEdit(form);
    },
    doAdd(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          add(this.form)
            .then(res => {
              this.resetForm();
              this.$notify({
                title: "添加成功",
                type: "success",
                duration: 2500
              });
              this.loading = false;
              this.$parent.$parent.init();
            })
            .catch(err => {
              this.loading = false;
              console.log(err.response.data.message);
            });
        } else {
          this.loading = false;
        }
      });
    },
    doEdit(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          edit(this.form)
            .then(res => {
              this.resetForm();
              this.$notify({
                title: "修改成功",
                type: "success",
                duration: 2500
              });
              this.loading = false;
              this.sup_this.init();
            })
            .catch(err => {
              this.loading = false;
              console.log(err.response.data.message);
            });
        } else {
          this.loading = false;
        }
      });
    },
    resetForm() {
      this.dialog = false;
      this.$refs["form"].resetFields();
      this.form = {
        id: "",
        typeName: "",
        typeNameEn: "",
        status: "1"
      };
    }
  }
};
</script>

<style scoped>
</style>
